.modul3__two {
    max-width: 1080px;
    margin: auto;
}

.modul3__two > h1,
.modul3__two-text {
    color: var(--color-black);
    text-align: center;
}

.modul3__two-text p:not(:last-of-type) {
    margin-bottom: 1rem;
}

.modul3__two-text > p a {
    color: #2596be;
}

.modul3__two-boxes  {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem;
    row-gap: 1.5rem;
    margin-top: 2rem;
}

.modul3__two-boxes .Collapsible {
    background-color: var(--color-fifth);
    padding: 1.5rem 1rem 1.5rem 2rem;
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-sixth);
            box-shadow: 7px 7px 0px 0px var(--color-sixth);
    overflow: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.modul3__two-boxes .Collapsible:last-of-type {
    margin-bottom: 2rem;
    -ms-grid-column: 2;
    grid-column: 2;
}

.modul3__two-boxes .Collapsible__trigger {
    max-height: 3rem;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.modul3__two-boxes .Collapsible__trigger div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    color: var(--color-black);
    font-family: var(--font-family);
    font-size: 26px;
    font-weight: bold;
}

.modul3__two-boxes .Collapsible__contentInner {
    padding: 1rem 2rem 1rem 2rem;
    color: var(--color-black);
}

.modul3__two-boxes .Collapsible__contentInner li {
    text-align: left;
}

@media screen and (max-width: 1350px) {
    .modul3__two-boxes .Collapsible__trigger div {
        font-size: 20px;
    }
}

@media screen and (max-width: 1000px) {  
    .modul3__two-boxes  {
        -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    }

    .modul3__two-boxes .Collapsible:last-of-type {
        margin-bottom: 2rem;
        -ms-grid-column: 1;
        grid-column: 1;
    }
}

@media screen and (max-width: 700px) {
    .modul3__two-boxes  {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-column-gap: unset;
           -moz-column-gap: unset;
                column-gap: unset;
        row-gap: unset;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .modul3__two-boxes .Collapsible {
        margin-bottom: 2rem;
        text-align: left;
    }
    
    .modul3__two-boxes .Collapsible__trigger div {
        font-size: 22px;
    }

    .modul3__two-boxes .Collapsible__contentInner {
        padding: 1.3rem;
    }
}

@media screen and (max-width: 450px) {
    .modul3__two-boxes .Collapsible {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-sixth);
                box-shadow: 4px 4px 0px 0px var(--color-sixth);
        max-width: 90%;
    }
}

@media screen and (max-width: 350px) {
    .modul3__two-boxes .Collapsible__trigger div h3 {
        font-size: 22px !important;
    }
}