.modul3__three {
    max-width: 900px;
    margin: auto;
    color: var(--color-black);
    text-align: center;
}

.modul3__three p a {
    color: #2596be;
}

.modul3__three > div {
    max-width: 600px;
    margin: auto;
    margin-top: 2rem;
}

@media screen and (max-width: 1000px) {
    .modul3__three {
        text-align: left;
    }

    .modul3__three > div {
        max-width: 900px;
    }
}