.modul1__five {
    max-width: 1080px;
    margin: auto;
}

.modul1__five > .hoja__columns {
    margin-bottom: 2rem;
}

.modul1__five-text {
    color: var(--color-black);
}

.modul1__five > .hoja__columns div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 1000px) {
    .modul1__five-text .hoja__spotlight {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .modul1__five > .hoja__columns div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}