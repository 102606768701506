.hoja__modul5 {
    background-color: var(--color-white);
}

.hoja__modul5-header {
    background-image: url('../../assets/Generell bakgrund_grön.jpg');
    background-size: contain;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.hoja__modul5-intro > div {
    max-width: 800px;
    margin: auto;
}

.hoja__modul5-intro .hoja__highlight h1 {
    display: none;
}

.hoja__modul5-two,
.hoja__modul5-four,
.hoja__modul5-six {
    background: var(--color-primary);
}

.hoja__modul5-ending {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 1080px;
    margin: auto;
}

.hoja__modul5-ending div {
    width: 16rem;
    height: 6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.hoja__modul5-ending a {
    position: relative;
    background-color: var(--color-primary);
    width: 16rem;
    height: 6rem;
    -webkit-filter: drop-shadow(7px 7px 0px var(--color-secondary));
            filter: drop-shadow(7px 7px 0px var(--color-secondary));
    cursor: pointer;
    -webkit-transition: all 0.25 ease-in-out;
    -o-transition: all 0.25 ease-in-out;
    transition: all 0.25 ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hoja__modul5-ending_prev:after,
.hoja__modul5-ending_prev::after {
    position: absolute;
    top: 0px;
    left: -3rem;
    content: '';
    width: 0;
    height: 0;
    border-right: solid 3rem var(--color-primary);
    border-top: solid 3rem transparent;
    border-bottom: solid 3rem transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hoja__modul5-ending_next:after,
.hoja__modul5-ending_next::after {
    position: absolute;
    top: 0px;
    right: -3rem;
    content: '';
    width: 0;
    height: 0;
    border-left: solid 3rem var(--color-primary);
    border-top: solid 3rem transparent;
    border-bottom: solid 3rem transparent;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hoja__modul5-ending a:hover {
    color:white;
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
}

.hoja__modul5-ending a:hover:after,
.hoja__modul5-ending a:hover::after {
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2);
}

@media  screen and (max-width:850px) {
    .hoja__modul5-ending {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: unset;
            -ms-flex-pack: unset;
                justify-content: unset;
    }
}

@media  screen and (max-width:400px) {
    .hoja__modul5-zero > div {
        max-width: 280px;
        margin: auto;
    }

    
    .hoja__modul5-intro > div {
        max-width: 260px;
        margin: auto;
    }

    .hoja__modul5-ending div {
        width: 14rem;
        height: 6rem;
    }
    
    .hoja__modul5-ending a {
        width: 14rem;
        height: 6rem;
        -webkit-filter: drop-shadow(4px 4px 0px var(--color-secondary));
                filter: drop-shadow(4px 4px 0px var(--color-secondary));
    }

    .hoja__modul5-ending_prev,
    .hoja__modul5-ending_next h2,
    .hoja__modul5-ending_next p {
        margin-left: 2rem;
    }

    .hoja__modul5-ending_next,
    .hoja__modul5-ending_prev h2,
    .hoja__modul5-ending_prev p {
        margin-right: 2rem;
    }
}