.modul2__two {
    max-width: 1080px;
    margin: auto;
}

.modul2__two > h1 {
    color: var(--color-black);
    overflow-wrap: break-word;
}

.modul2__two > div:last-child {
    margin-top: 3rem;
}