* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family);
}

h1 {
  font-weight: 800;
  font-size: 38px!important;
  line-height: 40px;
  letter-spacing: -0.04em;
  margin-top: 0!important;
}

h2 {
  font-size: 20px;
  font-weight: 800;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border: 0;
}

.section__padding {
  padding: 4rem 0;
}

.hoja__columns {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.hoja__columns > div {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2rem;
}

.anchor {
  display: block;
  position: relative;
  top: -9rem;
  visibility: hidden;
}

@media screen and (max-width: 1300px) {
  .section__padding {
      padding: 4rem 6rem;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
      font-size: 38px!important;
      line-height: 40px;
  }

  h2 {
    font-size: 20px!important;
    font-weight: 800;
  }
  
  p {
      font-size: 16px;
      line-height: 24px;
  }

  .hoja__columns {
    flex-direction: column;
  }

  .hoja__columns > div {
    padding: 0;
  }

  .hoja__columns > div:not(:last-child) {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
}