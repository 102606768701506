.landing__one {
    max-width: 1380px;
    margin: auto;
}

.landing__one .hoja__highlight {
    height: 100%;
}


@media screen and (max-width: 1000px) {
    .landing__one {
        max-width: 800px;
    }
    
    .landing__one .hoja__highlight {
        height: unset;
    } 
}