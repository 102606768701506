.landing__three {
    max-width: 1180px;
    margin: auto;
}

.landing__three .hoja__highlight:not(:last-of-type) {
    margin-bottom: 2rem;
}

.landing__three .hoja__highlight h1 {
    text-align: left;
}

.landing__three-highlight {
    background: var(--color-secondary);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
}

.landing__three-highlight h1 {
    margin-bottom: 1rem;
    color: var(--color-black);
    text-align: left;
}

.landing__three-highlight_container {
    padding: 3rem;
}

.landing__three-highlight_container p:first-child,
.landing__three-highlight_container ol {
    margin-bottom: 1rem;
}

.landing__three-highlight ol li {
    list-style-position: inside;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 1rem;
}

.landing__three-highlight p a {
    color: #2596be;
}

@media screen and (max-width: 400px) {
    .landing__three-highlight h1,
    .landing__three .hoja__highlight h1 {
        overflow-wrap: break-word;
    }

    .landing__three-highlight {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-primary);
                box-shadow: 4px 4px 0px 0px var(--color-primary);
        margin: 1rem
    }

    .landing__three-highlight_container {
        padding: 2rem 2rem 1.3rem;
    }
}