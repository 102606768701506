iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
    background-color: var(--color-primary);
    border: none;
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
}

@media screen and (max-width: 400px) {
    iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-primary);
                box-shadow: 4px 4px 0px 0px var(--color-primary);
    }
}