.modul5__three {
    max-width: 1080px;
    margin: auto;
}

.modul5__three > h1 {
    padding-left: 2rem;
}

.modul5__three > .hoja__columns {
    margin-bottom: 2rem;
}

.modul5__three > .hoja__columns > div:last-of-type {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media screen and (max-width: 1000px) {
    .modul5__three > h1 {
        padding-left: 0;
    }
    
}