.modul3__four {
    max-width: 1080px;
    margin: auto;
}

.modul3__four > .hoja__columns {
    margin-bottom: 2rem;
}

.modul3__four-text {
    color: var(--color-black);
}

.modul3__four-text p:first-of-type {
    margin-bottom: 1rem;
}

.modul3__four .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 400px) {
    .modul3__four .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}