.modul1__four {
    max-width: 1080px;
    margin: auto;
}

.modul1__four h1 {
    padding-left: 2rem;
}

.modul1__four > .hoja__columns {
    margin-bottom: 2rem;
}

.modul1__four-text {
    color: var(--color-black);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media screen and (max-width: 1000px) {
    .modul1__four h1 {
        padding-left: 0;
    }
    
    .modul1__four-text {
        -webkit-box-pack: unset;
            -ms-flex-pack: unset;
                justify-content: unset;
    }
}