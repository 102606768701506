.modul6__one > div {
    max-width: 900px;
    margin: auto;
}

.modul6__one-highlight {
    background: var(--color-secondary);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
}

.modul6__one-highlight h1 {
    margin-bottom: 1rem;
    color: var(--color-black);
    text-align: left;
}

.modul6__one-highlight_container {
    padding: 3rem;
}

.modul6__one-highlight_container p:first-child,
.modul6__one-highlight_container ul {
    margin-bottom: 1rem;
}

.modul6__one-highlight ul {
    list-style: none;
    padding: 0 0.3rem;
    margin: 0;
    color: var(--color-black);
}

.modul6__one-highlight ul li {
    margin-bottom: 1rem;
    margin-left: 0;
    position: relative;
    padding-left: 1.5rem;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.modul6__one-highlight ul li::before {
    content: '- ';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
}