.modul5__two {
    max-width: 1080px;
    margin: auto;
}

.modul5__two > .hoja__columns {
    margin-bottom: 3rem;
}

.modul5__two-text {
    color: var(--color-black);
}

.modul5__two-text p:first-of-type {
    margin-bottom: 1rem;
}

.modul5__two-text p {
    margin-bottom: 3rem;
}

.modul5__two-video .hoja__video {
    margin-bottom: 8rem;
}

.modul5__two > div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 1000px) {
    .modul5__two-text p {
        margin-bottom: 2rem;
    }

    .modul5__two-video {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .modul5__two-video .hoja__video {
        margin-bottom: unset;
    }

    .modul5__two-video p {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .modul5__two > div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}