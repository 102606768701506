.modul4__three {
    max-width: 1080px;
    margin: auto;
}

.modul4__three > .hoja__columns {
    margin-bottom: 3rem;
}

.modul4__three-text {
    color: var(--color-black);
}