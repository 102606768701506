.modul4__four {
    max-width: 1080px;
    margin: auto;
}

.modul4__four > .hoja__columns {
    margin-bottom: 3rem;
}

.modul4__four-text {
    color: var(--color-black);
}

.modul4__four > div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 400px) {
    .modul4__four > div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}