.hoja__navmenu-hidden,
.hoja__navmenu {
    top: 0;
    left: 0;
    height: 100%;
    width: 5%;
    padding: 4rem 1rem;
    position: fixed;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    z-index: 4;

    /* ff 3.6+ */ 

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#000000', GradientType=0 );

    /* ie 10+ */

    /* global 94%+ browsers support */
    background:-o-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background:-webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 1)), to(rgba(255, 255, 255, 0)));
    background:linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.hoja__navmenu:hover {
  width: 60%;
}

.hoja__navmenu-waypoints,
.hoja__navmenu-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 100%;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  padding: 8rem 0 0 0.5rem;
}

.hoja__navmenu:hover .hoja__navmenu-waypoints,
.hoja__navmenu:hover .hoja__navmenu-line {
  padding: 8rem 60rem 0 0;
}

.hoja__navmenu-waypoints {
  z-index: 9;
}

.hoja__navmenu-waypoints > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.waypoint {
  color: black;
  font-size: 25px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.waypoint-active {
  color: var(--color-fourth);
  font-size: 25px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.p-active {
  color: var(--color-fourth) !important;
}

.hoja__navmenu:hover .waypoint,
.hoja__navmenu:hover .waypoint-active {
  font-size: 36px;
}

.hoja__navmenu:hover .waypoint {
  color: white;
}

.hoja__navmenu:hover .line {
  background-color: white;
  margin-top: 26px;
  height: calc(100% - 52px);
}

.hoja__navmenu-waypoints > div p {
  display: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.hoja__navmenu:hover .hoja__navmenu-waypoints > div p {
  display: unset;
  white-space: nowrap ;
  color: white;
  line-height: 2.2;
}

.buffer {
  visibility: hidden;
}

.line {
  margin-top: 18px;
  width: 3px;
  height: calc(100% - 36px);
  background-color: black;
}

.navmenu__hover-underline-animation,
.navmenu__hover-underline-animation-active {
  display: inline-block;
  position: relative;
}

.navmenu__hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  -webkit-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  -o-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.navmenu__hover-underline-animation-active:after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color-fourth);
  -webkit-transform-origin: bottom right;
      -ms-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  -o-transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.navmenu__hover-underline-animation:hover:after,
.navmenu__hover-underline-animation-active:hover:after {
  -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
          transform-origin: bottom left;
}

.move-back {
	-webkit-animation: move-back 0.2s ease-in-out both;
	animation: move-back 0.2s ease-in-out both;
}

  @-webkit-keyframes move-back {
    0% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
      }
      100% {
          -webkit-transform: translateX(-200px);
                  transform: translateX(-200px);
      }
  }
  
  @keyframes move-back {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
    100% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
    }
  }

  .move-forward {
    -webkit-animation: move-forward 0.2s ease-in-out both;
    animation: move-forward 0.2s ease-in-out both;
  }
  
    @-webkit-keyframes move-forward {
      0% {
          -webkit-transform: translateX(-200px);
                  transform: translateX(-200px);
        }
        100% {
            -webkit-transform: translateX(0px);
                    transform: translateX(0px);
        }
    }
    
    @keyframes move-forward {
      0% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
      }
      100% {
          -webkit-transform: translateX(0px);
                  transform: translateX(0px);
      }
    }

@media screen and (max-width: 1900px) {
  .hoja__navmenu:hover .hoja__navmenu-waypoints,
  .hoja__navmenu:hover .hoja__navmenu-line {
    padding: 8rem 50rem 0 0;
  }
}

@media screen and (max-width: 1650px) {
  .hoja__navmenu:hover .hoja__navmenu-waypoints,
  .hoja__navmenu:hover .hoja__navmenu-line {
    padding: 8rem 40rem 0 0;
  }
}

@media screen and (max-width: 1300px) {
  .hoja__navmenu:hover .hoja__navmenu-waypoints,
  .hoja__navmenu:hover .hoja__navmenu-line {
    padding: 8rem 30rem 0 0;
  }
}

@media screen and (max-width: 1000px) {
  .hoja__navmenu {
    display: none;
  }
}