.bm-burger-button {
    display: none;
    position: fixed;
    width: 32px;
    height: 32px;
    right: 42px;
    top: 2rem;
    color: var(--color-white);
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.bm-burger-button_hidden {
    position: fixed;
    width: 32px;
    height: 32px;
    right: 42px;
    top: -3rem;
    color: var(--color-white);
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-button:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    color: var(--color-white);
    width: 36px !important;
    height: 36px !important;
    right: 341px !important;
    top: 38px !important;
}

.bm-cross-button:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    width: 400px !important;
}

/* General sidebar styles */
.bm-menu {
    background: #222;
    padding: 10em 0 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: var(--color-white);
    height: calc(100% - 10em);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.bm-item-list::-webkit-scrollbar {
    display: none;
  }

/* Individual item */
.bm-item:not(.Collapsible) {
    width: 100%;
    height: 5rem;
    border-top: var(--color-white) solid 2px;
    padding: 0 2rem;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.bm-item:last-child {
    border-bottom: var(--color-white) solid 2px;
}

.bm-item:focus {
    outline: none;
}

.bm-item p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.sidebar__hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .sidebar__hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    -webkit-transform-origin: bottom right;
        -ms-transform-origin: bottom right;
            transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }

  .sidebar__hover-underline-animation:hover:after,
  .bm-item-list .Collapsible__trigger:hover .sidebar__hover-underline-animation:after,
  .bm-item:hover .sidebar__hover-underline-animation:after {
    -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
            transform-origin: bottom left;
  }

  .bm-item-list .Collapsible__trigger div div {
    color: #ecf0f1;
    width: 50px;
    height: 50px;
    border: 0;
    font-size: 1.5em;
    position: relative;
  }
  
  .bm-item-list .Collapsible__trigger div div span {
    position: absolute;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    background: white;
    border-radius: 2px;
  }
  
  /* Create the "+" shape by positioning the spans absolutely */
  .bm-item-list .Collapsible__trigger div div span:first-child {
    top: 25%;
    bottom: 25%;
    width: 10%;
    left: 45%;
  }
  
  .bm-item-list .Collapsible__trigger div div span:last-child {
    left: 25%;
    right: 25%;
    height: 10%;
    top: 45%;
  }
  
  /* Morph the shape when the button is hovered over */
  .bm-item-list .Collapsible__trigger .clicked span {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  
  .bm-item-list .Collapsible__trigger .clicked span:last-child {
    left: 50%;
    right: 50%;
  }

/* Styling of overlay */
.bm-overlay {
    background: rgba(34, 34, 34, 0.3) !important;
}

.bm-item-list .Collapsible {
    overflow: hidden;
    width: 100%;
    border-top: var(--color-white) solid 2px;
    padding: 0 2rem;
}

.bm-item-list .Collapsible__trigger {
    cursor: pointer;
}

.bm-item-list .Collapsible__trigger div {
    height: 5rem;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.bm-item-list .Collapsible__trigger div p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.bm-item-list .Collapsible__contentInner {
    padding: 1rem 2rem 1rem 2rem;
}

.bm-item-list .Collapsible__contentInner ul {
    list-style: none;
    padding: 0 0.3rem;
    margin: 0;
    color: var(--color-white);
}

.bm-item-list .Collapsible__contentInner ul li {
    margin-bottom: 1rem;
    margin-left: 0;
    position: relative;
    padding-left: 2rem;
    font-weight: bold;
}

.bm-item-list .Collapsible__contentInner ul li p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.bm-item-list .Collapsible__contentInner ul li::before {
    content: '- ';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
}

.body__menu-open {
    overflow:hidden;
}

@media screen and (max-width: 1200px) {
    .bm-cross-button,
    .bm-cross-button svg,
    .bm-cross-button button {
        right: 374px !important;
    }

    .bm-menu-wrap {
        width: 450px !important;
    }
}

@media screen and (max-width: 800px) {
    .bm-burger-button {
        display: unset;
    }

    .bm-cross-button,
    .bm-cross-button svg,
    .bm-cross-button button {
        right: 224px !important;
    }

    .bm-menu-wrap {
        width: 300px !important;
    }
}