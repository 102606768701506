.hoja__highlight {
    background: var(--color-secondary);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
}

.hoja__highlight h1 {
    margin-bottom: 1rem;
    color: var(--color-black);
    text-align: center;
}

.hoja__highlight-container {
    padding: 3rem;
}

.hoja__highlight p {
    color: var(--color-black);
}

.hoja__highlight-container p:not(:last-of-type) {
    margin-bottom: 1rem;
}

@media screen and (max-width: 400px) {
    .hoja__highlight {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-primary);
                box-shadow: 4px 4px 0px 0px var(--color-primary);
        margin: 1rem
    }

    .hoja__highlight-container {
        padding: 2rem 2rem 1.3rem;
    }
}