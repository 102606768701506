.landing__five {
    max-width: 1180px;
    margin: auto;
}

.landing__five .hoja__columns {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.landing__five .hoja__columns div {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.landing__five .hoja__columns div p:not(:last-of-type) {
    margin-bottom: 1rem;
}

.landing__five .hoja__columns div p a {
    color: #2596be;
}

.landing__five .hoja__columns img {
    max-width: 400px;
}

@media screen and (max-width: 1150px) {
    .landing__five .hoja__columns img {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
                align-self: center;
    }
}

@media screen and (max-width: 1000px) {
    .landing__five .hoja__columns {
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .landing__five {
        max-width: 400px;
        margin: auto;
    }

    .landing__five .hoja__columns img {
        max-width: 260px;
    }
}

@media screen and (max-width: 400px) {
    .landing__five {
        max-width: 300px;
        margin: auto;
    }
}
