@font-face {
  font-family: 'TheSans';
  src: local(TheSans), url('./fonts/thesans/TheSans\ Plain.otf') format('truetype');
  font-weight: normal;
}

:root {
  --font-family: 'TheSans';
  
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-primary:  rgb(198,221,128);
  --color-secondary: rgb(221,236,191);
  --color-third: rgb(255,233,189);
  --color-fourth: rgb(245, 194, 0);
  --color-fifth: rgb(200,165,210);
  --color-sixth: rgb(135,41,150);
}