.modul1__one {
    max-width: 1080px;
    margin: auto;
}

.modul1__one > .hoja__columns {
    margin-bottom: 3rem;
}

.modul1__one-text {
    color: var(--color-black);
}

.modul1__one-text p:not(:last-child) {
    margin-bottom: 1rem;
}

.modul1__one > div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

.modul1__one .hoja__listBox {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-bottom: 2rem;
}

@media screen and (max-width: 400px) {
    .modul1__one > div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}