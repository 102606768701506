.modul5__five {
    max-width: 1080px;
    margin: auto;
}

.modul5__five > .hoja__columns {
    margin-bottom: 3rem;
}

.modul5__five-second > div:last-of-type {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media screen and (max-width: 1000px) {
    .modul5__five-second{
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
    }

    .modul5__five > .hoja__columns {
        margin-bottom: 2rem;
    }

    .modul5__five-second > div:last-of-type {
        -webkit-box-pack: unset;
            -ms-flex-pack: unset;
                justify-content: unset;
        margin-bottom: 2rem;
    }

    .modul5__five-second > div:not(:last-child) {
        margin-bottom: 0 !important;
    }
}