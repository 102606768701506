.modul2__three {
    max-width: 1080px;
    margin: auto;
}

.modul2__three h1,
.modul2__three-text {
    color: var(--color-black);
}

.modul2__three .hoja__columns > div .hoja__video {
    margin-bottom: 2rem;
}

.modul2__three .hoja__columns > div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

.modul2__three-text p:not(:last-child) {
    margin-bottom: 1rem;
}

@media screen and (max-width: 1000px) {
    .modul2__three .hoja__columns {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .modul2__three-text p {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .modul2__three .hoja__columns > div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
    
}