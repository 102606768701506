.modul3__one {
    max-width: 1080px;
    margin: auto;
}

.modul3__one .hoja__columns {
    margin-bottom: 2rem;
}

.modul3__one-text {
    color: var(--color-black);
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}