.modul1__three {
    max-width: 1080px;
    margin: auto;
}

.modul1__three-text h1,
.modul1__three-text h2 {
    color: var(--color-black);
}

.modul1__three-text p {
    margin-bottom: 1rem;
    color: var(--color-black);
}

.modul1__three > .hoja__columns > div:first-child p:first-of-type {
    margin-bottom: 2rem;
}

.modul1__three-text ul {
    list-style: none;
    padding: 0 0.3rem;
    margin: 0;
    color: var(--color-black);
}

.modul1__three-text ul li {
    margin-bottom: 1rem;
    margin-left: 0;
    position: relative;
    padding-left: 2rem;
}

.modul1__three-text ul li::before {
    content: '- ';
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
}

.modul1__three .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 1630px) {
    .modul1__three-text ul li::before {
        line-height: 18px!important;
    }
}

@media screen and (max-width:1000px) {
    .modul1__three-text ul li::before {
        font-size: 16px;
        line-height: 20px!important;
    }
}

@media screen and (max-width: 820px) {
    .modul1__three-text ul li {
        padding-left: 1.5rem;
    }
}

@media screen and (max-width: 400px) {
    .modul1__three .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}