.hoja__navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.4rem 6rem 0.4rem 1.5rem;
    background-color: #222;
    position: fixed;
    width: 100%;
    z-index: 5;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hoja__navbar-hidden {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.4rem 6rem 0.4rem 1.5rem;
    background-color: #222;
    position: fixed;
    width: 100%;
    z-index: 5;
    -webkit-transform: translateY(-101%);
        -ms-transform: translateY(-101%);
            transform: translateY(-101%);
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.hoja__navbar-links {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.hoja__navbar-links_logo {
    margin-right: 2rem;
    max-width: 5rem;
    max-height: 5rem;
    overflow: hidden;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.hoja__navbar-links_logo img {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.hoja__navbar-links_logo:hover img {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.hoja__navbar-links_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.hoja__navbar-links_container p a,
.hoja__navbar-dropdown p,
.hoja__navbar-sign p a {
    color: var(--color-white);
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 1rem;
    margin: 0 1rem;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.hoja__navbar-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.hoja__navbar-dropdown > p {
    z-index: 1;
    cursor: default;
    background-color: #222;
}

.hoja__navbar-dropdown_container p,
.hoja__navbar-dropdown_container p a {
    color: var(--color-white);
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 1rem !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.hoja__navbar-dropdown_container {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: left;
        -ms-flex-align: left;
            align-items: left;
    padding: 2rem 0 0.7rem;
    background-color: rgba(34,34,34,0.8);
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

.navbar__hover-underline-animation {
    display: inline-block;
    position: relative;
}
  
.navbar__hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    -webkit-transform-origin: bottom right;
        -ms-transform-origin: bottom right;
            transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
  
.navbar__hover-underline-animation:hover:after {
    -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
            transform-origin: bottom left;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: translateY(-2.5rem);
                transform: translateY(-2.5rem);
                opacity: 0;
      }
      100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
                  opacity: 1;
      }
  }
  
  @keyframes scale-up-center {
    0% {
        -webkit-transform: translateY(-2.5rem);
                transform: translateY(-2.5rem);
                opacity: 0;
      }
      100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
                  opacity: 1;
      }
  }

  .scale-down-center {
	-webkit-animation: scale-down-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-down-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

  @-webkit-keyframes scale-down-center {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
      }
      100% {
          -webkit-transform: translateY(-2.5rem);
                  transform: translateY(-2.5rem);
                  opacity: 0;
                  visibility: hidden;
      }
  }
  
  @keyframes scale-down-center {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
      }
      100% {
          -webkit-transform: translateY(-2.5rem);
                  transform: translateY(-2.5rem);
                  opacity: 0;
                  visibility: hidden;
      }
  }

  @media screen and (max-width: 1200px) {
    .hoja__navbar {
        padding-right: 2rem;
    }
    
}

@media screen and (max-width: 800px) {
    .hoja__navbar-links_container,
    .hoja__navbar-sign {
        display: none;
    }
}