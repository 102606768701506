.landing__two {
    max-width: 1080px;
    margin: auto;
}

.landing__two h1 {
    padding-left: 2rem;
}

.landing__two .hoja__columns:not(:last-of-type),
.landing__two > p {
    margin-bottom: 2rem;
}

.landing__two > p {
    padding: 0 2rem;
}

.landing__two-text {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.landing__two .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 1000px) {
    .landing__two h1 {
        padding-left: 0;
    }

    .landing__two > p {
        padding: 0;
    }
}

@media screen and (max-width: 400px) {
    .landing__two .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}