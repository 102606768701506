.modul5__six {
    max-width: 700px;
    margin: auto;
}

.modul5__six .hoja__highlight {
    background-color: var(--color-fifth);
}

.modul5__six .hoja__highlight {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-sixth);
            box-shadow: 7px 7px 0px 0px var(--color-sixth);
}

.modul5__six .hoja__highlight p a {
    color: #2596be;
}

@media screen and (max-width: 400px) {
    .modul5__six .hoja__highlight {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-sixth);
                box-shadow: 4px 4px 0px 0px var(--color-sixth);
    }
}