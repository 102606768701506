.hoja__gotop {
    bottom: 2rem;
    right: 26px;
    color: rgba(34,34,34,0.5);
    font-size: 42px;
    position: fixed;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-tap-highlight-color: rgba(34,34,34,0);
}

.hoja__gotop:hover {
    color: rgba(34,34,34,1);
    font-size: 45px;
    bottom: 1.9rem;
    right: 24px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.hoja__gotop:focus {
    outline: none;
}

.hoja__gotop-hidden {
    bottom: -6rem;
    right: 26px;
    color: rgba(34,34,34,0.5);
    font-size: 42px;
    position: fixed;
    z-index: 1;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}