.hoja__landing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.hoja__landing-two,
.hoja__landing-four {
    background-color: var(--color-primary);
}

.hoja__landing-header {
    /*background-color: var(--color-primary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
*/
    background-image: url('../../assets/Generell bakgrund_grön.jpg');
    background-size: contain;
    color: var(--color-white);
}

.hoja__landing-header .hoja__columns {
    padding-top: 7rem;
    max-width: 1400px;
    margin: auto;
}

.hoja__landing-header .hoja__columns div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.hoja__landing-header h1{
    font-size: 150px !important;
    margin-bottom: 3rem;
}

.hoja__landing-header h2 {
    font-size: 30px !important;
    margin-left: 0.5rem;;
}

.hoja__landing-header img {
    max-width: 600px;
}

@media screen and (max-width: 1400px) {
    .hoja__landing-header .hoja__columns {
        -ms-flex-pack: distribute;
            justify-content: space-around;
    }

    .hoja__landing-header .hoja__columns div {
        -webkit-box-flex: 0;
            -ms-flex: 0;
                flex: 0;
        white-space: nowrap;
    }

    .hoja__landing-header h1{
        font-size: 130px !important;
    }
    
    .hoja__landing-header h2 {
        font-size: 25px !important;
    }
}

@media screen and (max-width: 1280px) {  
    .hoja__landing-header img {
        max-width: 500px;
    }
}

@media screen and (max-width: 1180px) {
    .hoja__landing-header h1{
        font-size: 110px !important;
    }
    
    .hoja__landing-header h2 {
        font-size: 20px !important;
    }
    
    .hoja__landing-header img {
        max-width: 400px;
    }
}

@media screen and (max-width: 1000px) {
    .hoja__landing-header .hoja__columns {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .hoja__landing-header .hoja__columns div {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .hoja__landing-header h1{
        margin-bottom: 2.5rem;
    }
}

@media screen and (max-width: 550px) {
    .hoja__landing-header .hoja__columns div {
        white-space: unset;
        text-align: center;
    }

    .hoja__landing-header img {
        max-width: 100%;
    }
}
