.modul5__four {
    max-width: 1080px;
    margin: auto;
}

.modul5__four > h1 {
    padding-left: 2rem;
}

.modul5__four > .hoja__columns:not(:last-of-type) {
    margin-bottom: 2rem;
}

.modul5__four > .hoja__columns > div:last-of-type {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.modul5__four > .hoja__columns .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 1000px) {
    .modul5__four > h1 {
        padding-left: 0;
    }
}

@media screen and (max-width: 400px) {
    .modul5__four > .hoja__columns .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}