.landing__four {
    max-width: 1180px;
    margin: auto;
}

.landing__four h1 {
    margin-bottom: 1rem;
    color: var(--color-black);
}

.landing__four > p {
    max-width: 900px;
    margin: auto;
}

.landing__four p:not(:last-of-type) {
    margin-bottom: 1rem;
}

.landing__four > h1:last-of-type,
.landing__four > p:last-of-type {
    text-align: center;
}

.landing__four-grid {
    max-width: 1000px;
    margin: auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr;
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 2rem;
       -moz-column-gap: 2rem;
            column-gap: 2rem;
    row-gap: 1.5rem;
    margin-top: 2rem;
}

.landing__four-grid > a div {
    background-color: var(--color-fifth);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-sixth);
            box-shadow: 7px 7px 0px 0px var(--color-sixth);
    padding: 2.5rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    text-align: center;
    height: 100%;
}

.landing__four-grid > a div:hover {
    -webkit-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02);
}

.landing__four-grid > a div h1 {
    font-size: 26px !important;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.landing__four-grid > a div p {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.landing__four-grid > a div:hover h1,
.landing__four-grid > a div:hover p {
    color: var(--color-white);
}

@media screen and (max-width: 1000px) {
    .landing__four {
        text-align: center;
    }
}

@media screen and (max-width: 950px) {
    .landing__four-grid {
        max-width: 660px;
        -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 650px) {
    .landing__four-grid {
        max-width: 340px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .landing__four-grid > a div {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-sixth);
                box-shadow: 4px 4px 0px 0px var(--color-sixth);
    }   
}