.hoja__missing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 13rem 0 8rem;
}

.hoja__missing-container {
    max-width: 1080px;
    margin: auto;
    padding: 8rem 16rem;
    background-color: var(--color-secondary);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
}

.hoja__missing-container h1 {
    font-size: 100px !important;
}

.hoja__missing-container > h2 {
    font-size: 48px !important;
    margin-bottom: 2rem;
}

.hoja__missing-container div {
    background-color: var(--color-fifth);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-sixth);
            box-shadow: 7px 7px 0px 0px var(--color-sixth);
    padding: 1.5rem;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.hoja__missing-container div:hover {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
    color: var(--color-white);
}

@media screen and (max-width: 1200px) {
    .hoja__missing-container {
        padding: 8rem 12rem;
    }
}

@media screen and (max-width: 1000px) {
    .hoja__missing-container {
        padding: 8rem;
    }
}

@media screen and (max-width: 850px) {
    .hoja__missing-container {
        padding: 4rem;
    }

    .hoja__missing-container h1 {
        font-size: 80px !important;
    }
    
    .hoja__missing-container > h2 {
        font-size: 28px !important;
        text-align: center;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .hoja__missing-container {
        padding: 4rem;
    }

    .hoja__missing-container h1 {
        font-size: 60px !important;
    }
    
    .hoja__missing-container > h2 {
        font-size: 24px !important;
        text-align: center;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .hoja__missing-container {
        padding: 2rem;
    }

    .hoja__missing-container h1 {
        font-size: 40px !important;
        text-align: center;
    }
    
    .hoja__missing-container h2 {
        font-size: 18px !important;
    }

    .hoja__missing-container div {
        background-color: var(--color-fifth);
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-sixth);
                box-shadow: 4px 4px 0px 0px var(--color-sixth);
        padding: 1rem 0.5rem;
    }
}