.modul2__four {
    max-width: 1080px;
    margin: auto;
}

.modul2__four h1,
.modul2__four .hoja__columns p {
    color: var(--color-black);
}

.modul2__four .hoja__columns {
    margin-bottom: 2rem;
}