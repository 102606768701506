.hoja__intro-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.hoja__intro-container > h1 {
    color: var(--color-black);
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
}

.hoja__intro-container p {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    text-align: center;
    color: var(--color-black);
    max-width: 80%;
}

.hoja__intro-container p:not(:last-of-type) {
    margin-bottom: 1rem;
}