.modul4__five {
    max-width: 1080px;
    margin: auto;
}

.modul4__five > .hoja__columns {
    margin-bottom: 3rem;
}

.modul4__five-text {
    color: var(--color-black);
}

.modul4__five > h1 {
    margin-left: 2rem;
}

.modul4__five-tip{
    margin-bottom: 2rem !important;
    max-width: 650px;
    margin: auto;
}

.modul4__five-tip div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
}

.modul4__five-tip div h1 {
    font-size: 100px !important;
}

.modul4__five-tip div img {
    max-width: 450px;
}

@media screen and (max-width: 1000px) {
    .modul4__five > h1 {
        margin-left: 0;
    }
}

@media screen and (max-width: 530px) {
    .modul4__five-tip div img {
        max-width: 250px;
    }
}

@media screen and (max-width: 400px) {
    .modul4__five-tip div h1 {
        font-size: 60px !important;
    }
}