.modul6__three {
    max-width: 900px;
    margin: auto;
}

.modul6__three-content {
    background-color: var(--color-secondary);
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-primary);
            box-shadow: 7px 7px 0px 0px var(--color-primary);
    padding: 3rem;
}

.modul6__three-content span:not(:last-child) p {
    margin-bottom: 1rem;
}

.modul6__three-content a h2 {
    white-space: wrap;  
    color: #2596be;
}

.modul6__three-content a {
    color: #2596be;
}

.modul6__hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .modul6__hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
            transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #2596be;
    -webkit-transform-origin: bottom right;
        -ms-transform-origin: bottom right;
            transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    -o-transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }
  
  .modul6__hover-underline-animation:hover:after {
    -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
            transform-origin: bottom left;
  }

@media screen and (max-width: 400px) {
    .modul6__three-content {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-primary);
                box-shadow: 4px 4px 0px 0px var(--color-primary);
        padding: 2rem 2rem 1.3rem;
    }
}

@media screen and (max-width: 330px) {
    .modul6__three-content span:last-of-type a h2 {
        word-break: break-all;
    }
}