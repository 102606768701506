.hoja__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 12rem 3rem 5rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.hoja__header h1 {
    color: var(--color-white);
    font-size: 90px !important;
    margin-bottom: 2rem;
    text-align: center;
    white-space: wrap;
    line-height: 64px;
}

.hoja__header p {
    color: var(--color-white);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    white-space: wrap;
}

@media screen and (max-width: 900px) {
    .hoja__header h1 {
        font-size: 70px !important;
        margin-bottom: 1rem;
    }
    
    .hoja__header p {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .hoja__header h1 {
        font-size: 50px !important;
        margin-bottom: 0.4rem;
        line-height: 48px;
    }
    
    .hoja__header p {
        font-size: 16px;
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .hoja__header {
        padding: 12rem 1rem 5rem;
    }

    .hoja__header h1 {
        font-size: 38px !important;
        line-height: 38px;
        margin-bottom: 0.5rem;
    }
    
    .hoja__header p {
        font-size: 14px;
        text-align: center;
    }
}