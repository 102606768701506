.modul4__one {
    max-width: 1080px;
    margin: auto;
}

.modul4__one > .hoja__columns {
    margin-bottom: 3rem;
}

.modul4__one-text {
    color: var(--color-black);
}