.modul2__one {
    max-width: 1080px;
    margin: auto;
}

.modul2__one-text {
    color: var(--color-black);
}

.modul2__one-text p:not(:last-child) {
    margin-bottom: 1rem;
}

.modul2__one .hoja__columns div .hoja__video {
    margin-bottom: 2rem;
}

.modul2__one .hoja__columns div .hoja__video iframe {
    -webkit-box-shadow: 7px 7px 0px 0px var(--color-secondary);
            box-shadow: 7px 7px 0px 0px var(--color-secondary);
}

@media screen and (max-width: 400px) {
    .modul2__one .hoja__columns div .hoja__video iframe {
        -webkit-box-shadow: 4px 4px 0px 0px var(--color-secondary);
                box-shadow: 4px 4px 0px 0px var(--color-secondary);
    }
}