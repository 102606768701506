.modul1__six {
    max-width: 1080px;
    margin: auto;
}

.modul1__six > .hoja__columns {
    margin-bottom: 2rem;
}

.modul1__six-text {
    color: var(--color-black);
}

.modul1__six-text p:first-of-type {
    margin-bottom: 1rem;
}

.modul1__six .hoja__spotlight {
    margin-bottom: 2rem;
}

.modul1__six .hoja__listBox {
    max-width: 820px;
    margin: auto;
}

@media screen and (max-width: 1000px) {
    .modul1__six > .hoja__columns {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .modul1__six-text {
        margin-bottom: 2rem;
    }

    .modul1__six .hoja__columns div:not(:last-child) {
        margin-bottom: 0;
    }
}